import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_FIELD, GridCell, GridRenderCellParams } from '@mui/x-data-grid-premium';

type RenderCellProps = GridRenderCellParams & {
  value: string;
  isChangedCell: boolean;
  highLightedTextParts: string[] | null;
  isHighlightedOnRed: boolean;
};

export const RenderCell: FC<RenderCellProps> = ({
  value,
  highLightedTextParts,
  isHighlightedOnRed,
  isChangedCell,
  ...params
}) => {
  const { field, cellMode } = params;

  const isDisplayText = cellMode === 'view' && field !== GRID_CHECKBOX_SELECTION_FIELD;

  return (
    <Stack direction="row" sx={{ position: 'relative', overflow: 'hidden' }}>
      <GridCell {...params} formattedValue={''} />

      {isDisplayText && (
        <Typography
          noWrap
          sx={{
            position: 'absolute',
            top: 2.5,
            width: '100%',
            px: 1,
            pointerEvents: 'none',
            fontSize: 16,
            color: isChangedCell ? 'warning.main' : 'darksome.main',
            textDecoration: isChangedCell ? 'underline' : 'none',
            span: {
              backgroundColor: isHighlightedOnRed ? 'rgba(255, 150, 50, 0.8)' : 'rgba(253, 221, 86, 0.7)',
            },
          }}
        >
          {highLightedTextParts
            ? highLightedTextParts.map((w, i) => (i === 1 ? <span key={i}>{w}</span> : w))
            : value}
        </Typography>
      )}
    </Stack>
  );
};
