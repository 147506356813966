import { Box, MenuItem, Stack } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CATALOG_TYPE } from 'shared/constants';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { separateByUpperCase } from 'shared/lib';
import { ConstantFilter, Group, SubType } from 'shared/models';
import { selectCatalogTypes, setCatalogConstantFilters } from 'shared/slices';
import { FilterSelect } from 'shared/ui';

type Props = {
  filters?: ConstantFilter;
  groupItems: Group[];
  subtypeItems: SubType[];
  disabled?: boolean;
  isEditing: boolean;
  isSaveLoading: boolean;
};

export const CatalogConstantFilters: FC<Props> = ({
  filters,
  subtypeItems,
  groupItems,
  disabled,
  isEditing,
  isSaveLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { catalogId, type, onChangeType } = useCatalogSearchParams();

  const catalogTypes: string[] = useAppSelector(selectCatalogTypes);

  const isDistributionCurveType = type === CATALOG_TYPE.DISTRIBUTION_CURVE;
  const isNoTypes = !catalogTypes.length;
  const isDisabled = disabled || isEditing || isSaveLoading || isNoTypes;

  const subtypes = useMemo(
    () => subtypeItems.map((st) => st.name).sort((a, b) => a.localeCompare(b, undefined, { numeric: true })),
    [subtypeItems]
  );
  const groups = useMemo(() => {
    const selectedSubtype = filters?.subtype;
    const filteredGroups = selectedSubtype
      ? groupItems.filter((g) => g.subtype?.name === selectedSubtype)
      : groupItems;
    return filteredGroups.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
  }, [groupItems, filters?.subtype]);

  const groupValue = isDistributionCurveType ? filters?.groupName : filters?.group;

  useEffect(() => {
    onResetFilters('all');
  }, [type]);

  useEffect(() => {
    return () => {
      onResetFilters('all');
    };
  }, []);

  const onChangeFilter = (filterName: 'subtype' | 'group' | 'groupName', value: string) => {
    if (filters?.[filterName] === value) return;

    let shouldResetGroup = false;

    if (filterName === 'subtype') {
      const selectedGroup = isDistributionCurveType ? filters?.groupName : filters?.group;
      const selectedGroupSubtype = groupItems.find((g) => selectedGroup === g.name)?.subtype?.name;
      shouldResetGroup = Boolean(selectedGroup && selectedGroupSubtype !== value);
    }

    const updatedFilters = { [filterName]: value, ...(shouldResetGroup && { group: null, groupName: null }) };

    dispatch(setCatalogConstantFilters({ catalogId, filters: updatedFilters }));
  };

  const onChangeGroup = (value: string) => {
    const filterName = isDistributionCurveType ? 'groupName' : 'group';

    onChangeFilter(filterName, value);
  };

  const onResetFilters = (resetType: 'subtype' | 'group' | 'groupName' | 'all') => {
    const newFilters: Partial<ConstantFilter> =
      resetType === 'all' ? { subtype: null, group: null, groupName: null } : { [resetType]: null };

    dispatch(setCatalogConstantFilters({ catalogId, filters: newFilters }));
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack direction="row" alignItems="center" spacing={3} sx={{ px: 3, py: 1.5, bgcolor: '#FFF' }}>
        <FilterSelect
          label={t('type')}
          value={isNoTypes ? '' : type}
          disabled={isDisabled}
          options={catalogTypes}
          renderMenuItem={(type: string) => (
            <MenuItem key={type} value={type} onClick={() => onChangeType(type)}>
              {separateByUpperCase(type)}
            </MenuItem>
          )}
        />

        <FilterSelect
          label={t('subtype')}
          value={filters?.subtype ?? ''}
          disabled={isDisabled}
          clearFilter={() => onResetFilters('subtype')}
          allowEmpty
          options={subtypes}
          renderMenuItem={(subtype: string) => (
            <MenuItem key={subtype} value={subtype} onClick={() => onChangeFilter('subtype', subtype)}>
              {subtype}
            </MenuItem>
          )}
        />

        <FilterSelect<Group>
          label={t('group')}
          value={groupValue ?? ''}
          clearFilter={() => onResetFilters(isDistributionCurveType ? 'groupName' : 'group')}
          allowEmpty
          options={groups}
          renderOption={(group: Group) => group.name}
          renderMenuItem={({ id, name }: Group) => (
            <MenuItem key={id} value={isDistributionCurveType ? id : name} onClick={() => onChangeGroup(name)}>
              {name}
            </MenuItem>
          )}
        />
      </Stack>

      {isEditing && (
        <Box sx={{ position: 'absolute', width: '100%', height: '100%', bgcolor: 'rgba(52, 64, 84, 0.50)' }} />
      )}
    </Stack>
  );
};
