import {
  EmitterComponentProperties,
  FullSpecificationsProductInfo,
  GeneralComponentProperties,
  IntegralProductInfo,
  PipeComponentProperties,
  TableProductInfo,
} from 'shared/models';
import { mathRound } from '../general';
import { CATALOG_TYPE, CURVE_PLACES_AMOUNT, ORIENTATION } from 'shared/constants';

export const tableProductFactory = (type: string, product: FullSpecificationsProductInfo): TableProductInfo => {
  switch (type) {
    case CATALOG_TYPE.PIPE:
      return {
        id: product.component.id,
        sku: product.component.sku,
        description: product.component.description,
        stringResources: product.component.stringResources,
        brand: product.component?.brand?.name ?? null,
        brandId: product.component?.brand?.id ?? null,
        subtype: product.group?.subtype?.name ?? null,
        group: product.group.name,
        groupId: product.group.id,

        inlet: product.component.general.inlet ? mathRound(product.component.general.inlet, 3) : null,
        inletAlt: product.component.general.inletAlt ? mathRound(product.component.general.inletAlt, 3) : null,
        outlet: product.component.general.outlet ? mathRound(product.component.general.outlet, 3) : null,
        outletAlt: product.component.general.outletAlt ? mathRound(product.component.general.outletAlt, 3) : null,
        optimizationFactor1: product.component.general.optimizationFactor1
          ? mathRound(product.component.general.optimizationFactor1, 3)
          : null,
        optimizationFactor2: product.component.general.optimizationFactor2
          ? mathRound(product.component.general.optimizationFactor2, 3)
          : null,

        class: product.component.pipe.class ?? '',
        classAlt: product.component.pipe.classAlt ?? '',
        thicknes: product.component.pipe.thicknes ? mathRound(product.component.pipe.thicknes, 3) : null,
        intrnl: product.component.pipe.intrnl ? mathRound(product.component.pipe.intrnl, 3) : null,
        vmax: product.component.pipe.vmax ? mathRound(product.component.pipe.vmax, 3) : null,
        hwcof: product.component.pipe.hwcof ? mathRound(product.component.pipe.hwcof, 3) : null,
        pipedha: product.component.pipe.pipedha ? mathRound(product.component.pipe.pipedha, 3) : null,
        pipedhb: product.component.pipe.pipedhb ? mathRound(product.component.pipe.pipedhb, 3) : null,
        pipedhc: product.component.pipe.pipedhc ? mathRound(product.component.pipe.pipedhc, 3) : null,
      };

    case CATALOG_TYPE.EMITTER:
      return {
        id: product.component.id,
        sku: product.component.sku,
        description: product.component.description,
        stringResources: product.component.stringResources,

        brandId: product.component?.brand?.id ?? null,
        brand: product.component?.brand?.name ?? null,
        subtype: product.group?.subtype?.name ?? null,
        group: product.group.name,
        groupId: product.group.id,

        inlet: product.component.general.inlet ? mathRound(product.component.general.inlet, 3) : null,
        inletAlt: product.component.general.inletAlt ? mathRound(product.component.general.inletAlt, 3) : null,
        outlet: product.component.general.outlet ? mathRound(product.component.general.outlet, 3) : null,
        outletAlt: product.component.general.outletAlt ? mathRound(product.component.general.outletAlt, 3) : null,
        optimizationFactor1: product.component.general.optimizationFactor1
          ? mathRound(product.component.general.optimizationFactor1, 3)
          : null,
        optimizationFactor2: product.component.general.optimizationFactor2
          ? mathRound(product.component.general.optimizationFactor2, 3)
          : null,

        nozzle: product.component.emitter.nozzle ?? null,
        swivel: product.component.emitter.swivel ?? null,
        qnom: product.component.emitter.qnom ? mathRound(product.component.emitter.qnom, 3) : null,
        qnomAlt: product.component.emitter.qnomAlt ? mathRound(product.component.emitter.qnomAlt, 3) : null,

        pmin: product.component.emitter.pmin ? mathRound(product.component.emitter.pmin, 3) : null,
        pmax: product.component.emitter.pmax ? mathRound(product.component.emitter.pmax, 3) : null,
        pminAlt: product.component.emitter.pminAlt ? mathRound(product.component.emitter.pminAlt, 3) : null,
        pmaxAlt: product.component.emitter.pmaxAlt ? mathRound(product.component.emitter.pmaxAlt, 3) : null,
        pressureNominalM: product.component.emitter.pressureNominalM
          ? mathRound(product.component.emitter.pressureNominalM, 3)
          : null,
        pressureNominalPsi: product.component.emitter.pressureNominalPsi
          ? mathRound(product.component.emitter.pressureNominalPsi, 3)
          : null,
        kd: product.component.emitter.kd ? mathRound(product.component.emitter.kd, 3) : null,
        emitterqa: product.component.emitter.emitterqa ? mathRound(product.component.emitter.emitterqa, 3) : null,
        emitterqb: product.component.emitter.emitterqb ? mathRound(product.component.emitter.emitterqb, 3) : null,
      };

    case CATALOG_TYPE.INTEGRAL:
      return {
        id: product.component.id,
        sku: product.component.sku,
        description: product.component.description,
        stringResources: product.component.stringResources,

        brandId: product.component?.brand?.id ?? null,
        brand: product.component?.brand?.name ?? null,
        subtype: product.group?.subtype?.name ?? null,
        group: product.group.name,
        groupId: product.group.id,

        inlet: product.component.general.inlet ? mathRound(product.component.general.inlet, 3) : null,
        inletAlt: product.component.general.inletAlt ? mathRound(product.component.general.inletAlt, 3) : null,
        outlet: product.component.general.outlet ? mathRound(product.component.general.outlet, 3) : null,
        outletAlt: product.component.general.outletAlt ? mathRound(product.component.general.outletAlt, 3) : null,
        optimizationFactor1: product.component.general.optimizationFactor1
          ? mathRound(product.component.general.optimizationFactor1, 3)
          : null,
        optimizationFactor2: product.component.general.optimizationFactor2
          ? mathRound(product.component.general.optimizationFactor2, 3)
          : null,

        class: product.component.integral.pipe.class ?? '',
        classAlt: product.component.integral.pipe.classAlt ?? '',
        thicknes: product.component.integral.pipe.thicknes
          ? mathRound(product.component.integral.pipe.thicknes, 3)
          : null,
        intrnl: product.component.integral.pipe.intrnl
          ? mathRound(product.component.integral.pipe.intrnl, 3)
          : null,

        vmax: product.component.integral.pipe.vmax ? mathRound(product.component.integral.pipe.vmax, 3) : null,
        hwcof: product.component.integral.pipe.hwcof ? mathRound(product.component.integral.pipe.hwcof, 3) : null,
        pipedha: product.component.integral.pipe.pipedha
          ? mathRound(product.component.integral.pipe.pipedha, 3)
          : null,
        pipedhb: product.component.integral.pipe.pipedhb
          ? mathRound(product.component.integral.pipe.pipedhb, 3)
          : null,
        pipedhc: product.component.integral.pipe.pipedhc
          ? mathRound(product.component.integral.pipe.pipedhc, 3)
          : null,

        qnom: product.component.integral.emitter.qnom
          ? mathRound(product.component.integral.emitter.qnom, 3)
          : null,
        qnomAlt: product.component.integral.emitter.qnomAlt
          ? mathRound(product.component.integral.emitter.qnomAlt, 3)
          : null,
        spacing: product.component.integral.spacing ? mathRound(product.component.integral.spacing, 3) : null,
        spacingAlt: product.component.integral.spacingAlt
          ? mathRound(product.component.integral.spacingAlt, 3)
          : null,
        qlHr100m: product.component.integral.emitter.qlHr100m
          ? mathRound(product.component.integral.emitter.qlHr100m, 3)
          : null,
        qGpm100ft: product.component.integral.emitter.qGpm100ft
          ? mathRound(product.component.integral.emitter.qGpm100ft, 3)
          : null,
        pmin: product.component.integral.emitter.pmin
          ? mathRound(product.component.integral.emitter.pmin, 3)
          : null,
        pmax: product.component.integral.emitter.pmax
          ? mathRound(product.component.integral.emitter.pmax, 3)
          : null,
        pminAlt: product.component.integral.emitter.pminAlt
          ? mathRound(product.component.integral.emitter.pminAlt, 3)
          : null,
        pmaxAlt: product.component.integral.emitter.pmaxAlt
          ? mathRound(product.component.integral.emitter.pmaxAlt, 3)
          : null,
        pressureNominalM: product.component.integral.emitter.pressureNominalM
          ? mathRound(product.component.integral.emitter.pressureNominalM, 3)
          : null,
        pressureNominalPsi: product.component.integral.emitter.pressureNominalPsi
          ? mathRound(product.component.integral.emitter.pressureNominalPsi, 3)
          : null,
        kd: product.component.integral.emitter.kd ? mathRound(product.component.integral.emitter.kd, 3) : null,

        emitterqa: product.component.integral.emitter.emitterqa
          ? mathRound(product.component.integral.emitter.emitterqa, 3)
          : null,
        emitterqb: product.component.integral.emitter.emitterqb
          ? mathRound(product.component.integral.emitter.emitterqb, 3)
          : null,
      };

    default:
      return {
        id: product.component.id,
        sku: product.component.sku,
        description: product.component.description,
        stringResources: product.component.stringResources,

        brandId: product.component?.brand?.id ?? null,
        brand: product.component?.brand?.name ?? null,
        subtype: product.group?.subtype?.name ?? null,
        group: product.group.name,
        groupId: product.group.id,

        inlet: product.component.general.inlet ? mathRound(product.component.general.inlet, 3) : null,
        inletAlt: product.component.general.inletAlt ? mathRound(product.component.general.inletAlt, 3) : null,
        outlet: product.component.general.outlet ? mathRound(product.component.general.outlet, 3) : null,
        outletAlt: product.component.general.outletAlt ? mathRound(product.component.general.outletAlt, 3) : null,
        optimizationFactor1: product.component.general.optimizationFactor1
          ? mathRound(product.component.general.optimizationFactor1, 3)
          : null,
        optimizationFactor2: product.component.general.optimizationFactor2
          ? mathRound(product.component.general.optimizationFactor2, 3)
          : null,
      };
  }
};

export const getNewEmptyProduct = (type: string): TableProductInfo => {
  const generalValues = {
    id: Math.random().toString(),
    sku: '',
    description: '',
    brand: null,
    brandId: null,
    subtype: null,
    group: null,
    groupId: null,

    inlet: null,
    inletAlt: null,
    outlet: null,
    outletAlt: null,
    optimizationFactor1: null,
    optimizationFactor2: null,
  };

  let componentValues;

  switch (type) {
    case CATALOG_TYPE.PIPE:
      componentValues = {
        class: '',
        classAlt: '',
        thicknes: null,
        intrnl: null,
        vmax: null,
        hwcof: null,
        pipedha: null,
        pipedhb: null,
        pipedhc: null,
      };

      break;
    case CATALOG_TYPE.EMITTER:
      componentValues = {
        nozzle: null,
        swivel: null,
        qnom: null,
        qnomAlt: null,
        pmin: null,
        pmax: null,
        pminAlt: null,
        pmaxAlt: null,
        pressureNominalM: null,
        pressureNominalPsi: null,
        kd: null,
        emitterqa: null,
        emitterqb: null,
      };

      break;
    case CATALOG_TYPE.INTEGRAL:
      componentValues = {
        class: '',
        classAlt: '',
        thicknes: null,
        intrnl: null,
        vmax: null,
        hwcof: null,
        pipedha: null,
        pipedhb: null,
        pipedhc: null,

        spacing: null,
        spacingAlt: null,
        qnom: null,
        qnomAlt: null,
        qlHr100m: null,
        qGpm100ft: null,
        pmin: null,
        pmax: null,
        pminAlt: null,
        pmaxAlt: null,
        pressureNominalM: null,
        pressureNominalPsi: null,
        kd: null,
        emitterqa: null,
        emitterqb: null,
      };
      break;
    case CATALOG_TYPE.DISTRIBUTION_CURVE:
      {
        const places = [...new Array(CURVE_PLACES_AMOUNT)]
          .map((_, i) => ({ [`place${i}`]: i === 0 ? 0 : null }))
          .reduce((acc, c) => ({ ...acc, ...c }), {});

        componentValues = {
          id: Math.random().toString(),
          sku: '',
          description: '',

          nozzle: null,
          swivel: null,

          groupId: '',
          groupName: '',
          recNum: 0,
          orient: ORIENTATION.UPRIGHT as string,
          riser: null,
          pressure: null,
          basedis: null,
          flowRate: null,
          range: null,
          ...places,
        };
      }
      break;

    default:
      componentValues = {};
  }

  return { ...generalValues, ...componentValues };
};

export const newProductsDtoFactory = (type: string, payload: Partial<TableProductInfo>) => {
  const general = {
    inlet: payload['inlet'] ?? null,
    inletAlt: payload['inletAlt'] ?? null,
    outlet: payload['outlet'] ?? null,
    outletAlt: payload['outletAlt'] ?? null,
    optimizationFactor1: payload['optimizationFactor1'] ?? null,
    optimizationFactor2: payload['optimizationFactor2'] ?? null,
  };

  let componentValues;

  switch (type) {
    case CATALOG_TYPE.PIPE:
      componentValues = {
        pipe: {
          class: payload['class'] ?? '',
          classAlt: payload['classAlt'] ?? '',
          thicknes: payload['thicknes'] ?? null,
          intrnl: payload['intrnl'] ?? null,
          vmax: payload['vmax'] ?? null,
          hwcof: payload['hwcof'] ?? null,
          pipedha: payload['pipedha'] ?? null,
          pipedhb: payload['pipedhb'] ?? null,
          pipedhc: payload['pipedhc'] ?? null,
        },
      };
      break;
    case CATALOG_TYPE.EMITTER:
      componentValues = {
        emitter: {
          nozzle: payload['nozzle'] ?? null,
          swivel: payload['swivel'] ?? null,
          qnom: payload['qnom'] ?? null,
          qnomAlt: payload['qnomAlt'] ?? null,
          pmin: payload['pmin'] ?? null,
          pmax: payload['pmax'] ?? null,
          pminAlt: payload['pminAlt'] ?? null,
          pmaxAlt: payload['pmaxAlt'] ?? null,
          pressureNominalM: payload['pressureNominalM'] ?? null,
          pressureNominalPsi: payload['pressureNominalPsi'] ?? null,
          kd: payload['kd'] ?? null,
          emitterqa: payload['emitterqa'] ?? null,
          emitterqb: payload['emitterqb'] ?? null,
        },
      };
      break;
    case CATALOG_TYPE.INTEGRAL:
      componentValues = {
        integral: {
          spacing: payload['spacing'] ?? null,
          spacingAlt: payload['spacingAlt'] ?? null,
          pipe: {
            class: payload['class'] ?? null,
            classAlt: payload['classAlt'] ?? null,
            thicknes: payload['thicknes'] ?? null,
            intrnl: payload['intrnl'] ?? null,
            vmax: payload['vmax'] ?? null,
            hwcof: payload['hwcof'] ?? null,
            pipedha: payload['pipedha'] ?? null,
            pipedhb: payload['pipedhb'] ?? null,
            pipedhc: payload['pipedhc'] ?? null,
          },
          emitter: {
            qnom: payload['qnom'] ?? null,
            qnomAlt: payload['qnomAlt'] ?? null,
            qlHr100m: payload['qlHr100m'] ?? null,
            qGpm100ft: payload['qGpm100ft'] ?? null,
            pmin: payload['pmin'] ?? null,
            pmax: payload['pmax'] ?? null,
            pminAlt: payload['pminAlt'] ?? null,
            pmaxAlt: payload['pmaxAlt'] ?? null,
            pressureNominalM: payload['pressureNominalM'] ?? null,
            pressureNominalPsi: payload['pressureNominalPsi'] ?? null,
            kd: payload['kd'] ?? null,
            emitterqa: payload['emitterqa'] ?? null,
            emitterqb: payload['emitterqb'] ?? null,
          },
        },
      };
      break;

    default:
      componentValues = {};
  }

  return { general, ...componentValues };
};

export const updatedProductsDtoFactory = (
  type: string,
  changes: Partial<TableProductInfo>,
  initItem: TableProductInfo
) => {
  const general: Partial<GeneralComponentProperties> = {
    inlet: changes['inlet'] ?? initItem['inlet'],
    outlet: changes['outlet'] ?? initItem['outlet'],
    inletAlt: changes['inletAlt'] ?? initItem['inletAlt'],
    outletAlt: changes['outletAlt'] ?? initItem['outletAlt'],
    optimizationFactor1: changes['optimizationFactor1'] ?? initItem['optimizationFactor1'],
    optimizationFactor2: changes['optimizationFactor2'] ?? initItem['optimizationFactor2'],
  };

  let componentValues;

  switch (type) {
    case CATALOG_TYPE.PIPE:
      {
        const pipe: Partial<PipeComponentProperties> = {
          class: changes['class'] ?? initItem['class'],
          classAlt: changes['classAlt'] ?? initItem['classAlt'],
          thicknes: changes['thicknes'] ?? initItem['thicknes'],
          intrnl: changes['intrnl'] ?? initItem['intrnl'],
          vmax: changes['vmax'] ?? initItem['vmax'],
          hwcof: changes['hwcof'] ?? initItem['hwcof'],
          pipedha: changes['pipedha'] ?? initItem['pipedha'],
          pipedhb: changes['pipedhb'] ?? initItem['pipedhb'],
          pipedhc: changes['pipedhc'] ?? initItem['pipedhc'],
        };

        componentValues = { pipe };
      }
      break;
    case CATALOG_TYPE.EMITTER:
      {
        const emitter: Partial<EmitterComponentProperties> = {
          nozzle: 'nozzle' in changes ? changes['nozzle'] : initItem['nozzle'],
          swivel: changes['swivel'] ?? initItem['swivel'],
          qnom: changes['qnom'] ?? initItem['qnom'],
          qnomAlt: changes['qnomAlt'] ?? initItem['qnomAlt'],
          pmin: changes['pmin'] ?? initItem['pmin'],
          pmax: changes['pmax'] ?? initItem['pmax'],
          pminAlt: changes['pminAlt'] ?? initItem['pminAlt'],
          pmaxAlt: changes['pmaxAlt'] ?? initItem['pmaxAlt'],
          pressureNominalM: changes['pressureNominalM'] ?? initItem['pressureNominalM'],
          pressureNominalPsi: changes['pressureNominalPsi'] ?? initItem['pressureNominalPsi'],
          kd: changes['kd'] ?? initItem['kd'],
          emitterqa: changes['emitterqa'] ?? initItem['emitterqa'],
          emitterqb: changes['emitterqb'] ?? initItem['emitterqb'],
        };

        componentValues = { emitter };
      }
      break;
    case CATALOG_TYPE.INTEGRAL:
      {
        const pipe: Partial<PipeComponentProperties> = {
          class: changes['class'] ?? initItem['class'],
          classAlt: changes['classAlt'] ?? initItem['classAlt'],
          thicknes: changes['thicknes'] ?? initItem['thicknes'],
          intrnl: changes['intrnl'] ?? initItem['intrnl'],
          vmax: changes['vmax'] ?? initItem['vmax'],
          hwcof: changes['hwcof'] ?? initItem['hwcof'],
          pipedha: changes['pipedha'] ?? initItem['pipedha'],
          pipedhb: changes['pipedhb'] ?? initItem['pipedhb'],
          pipedhc: changes['pipedhc'] ?? initItem['pipedhc'],
        };
        const emitter: Partial<IntegralProductInfo> = {
          qnom: changes['qnom'] ?? initItem['qnom'],
          qnomAlt: changes['qnomAlt'] ?? initItem['qnomAlt'],
          pmin: changes['pmin'] ?? initItem['pmin'],
          pmax: changes['pmax'] ?? initItem['pmax'],
          pminAlt: changes['pminAlt'] ?? initItem['pminAlt'],
          pmaxAlt: changes['pmaxAlt'] ?? initItem['pmaxAlt'],
          pressureNominalM: changes['pressureNominalM'] ?? initItem['pressureNominalM'],
          pressureNominalPsi: changes['pressureNominalPsi'] ?? initItem['pressureNominalPsi'],
          kd: changes['kd'] ?? initItem['kd'],
          emitterqa: changes['emitterqa'] ?? initItem['emitterqa'],
          emitterqb: changes['emitterqb'] ?? initItem['emitterqb'],
          qlHr100m: changes['qlHr100m'] ?? initItem['qlHr100m'],
          qGpm100ft: changes['qGpm100ft'] ?? initItem['qGpm100ft'],
        };

        componentValues = {
          integral: {
            spacing: changes['spacing'] ?? initItem['spacing'],
            spacingAlt: changes['spacingAlt'] ?? initItem['spacingAlt'],
            pipe,
            emitter,
          },
        };
      }
      break;

    default:
      componentValues = {};
  }

  return { general, ...componentValues };
};
