import { axiosBase } from 'shared/axios';
import {
  BaseResponse,
  BulkElement,
  CatalogDTO,
  CatalogShareDTO,
  CatalogsResponse,
  EdcProfileDTO,
  FullSpecificationsProductInfo,
  MovedProductGroupDTO,
  UpdatedCurveDTO,
} from 'shared/models';
import { getTableProducts, requestWrapper, sortProducts } from 'shared/lib';
import { PRODUCT_KEY, SORTING } from 'shared/constants';

export class CatalogService {
  static async getCatalogs() {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<CatalogsResponse>>('/Catalogs'));
    if (data) return data.catalogs;
  }

  static async create(dto: CatalogDTO) {
    const { data } = await requestWrapper(axiosBase.post<BaseResponse<{ id: string }>>('/Catalogs', dto));
    if (data) return data.id;
  }

  static async renameCatalog(id: string, dto: CatalogDTO) {
    const { error } = await requestWrapper(axiosBase.put(`Catalogs/${id}`, dto));
    if (!error) return true;
  }

  static async delete(id: string) {
    const { error } = await requestWrapper(axiosBase.delete(`Catalogs/${id}`));
    if (!error) return true;
  }

  static async share(id: string, dto: CatalogShareDTO) {
    const { data } = await requestWrapper(axiosBase.post<BaseResponse<string[]>>(`/Catalogs/${id}/share`, dto));
    if (data) return data.pop();
  }

  static async deleteCatalogAccess(catalogId: string, sharingId: string) {
    const params = { data: [sharingId] };
    const { error } = await requestWrapper(axiosBase.delete(`/Catalogs/${catalogId}/share`, params));
    if (!error) return true;
  }

  static async changeCatalogAccess(catalogId: string, sharingId: string, dto: { permission: string }) {
    const { error } = await requestWrapper(axiosBase.put(`/Catalogs/${catalogId}/share/${sharingId}`, dto));
    if (!error) return true;
  }

  static async getProducts(catalogId: string, type: string, signal?: AbortSignal) {
    const params = { signal };
    const { data } = await requestWrapper(
      axiosBase.get<
        BaseResponse<{
          catalogs: FullSpecificationsProductInfo[];
        }>
      >(`Catalogs/${catalogId}/${type}`, params)
    );

    if (data) {
      let items = getTableProducts(data?.catalogs ?? [], type);
      items = items.sort((p1, p2) => sortProducts(p1, p2, PRODUCT_KEY.DESC, SORTING.ASC));
      return items;
    }
  }

  static async getTypes(signal?: AbortSignal) {
    const params = { signal };
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<string[]>>(`/Catalogs/types`, params));

    if (data) return data;
  }

  static async getCurrentCatalog(catalogId: string, signal?: AbortSignal) {
    const params = { signal };
    const { data } = await requestWrapper(
      axiosBase.get<BaseResponse<CatalogsResponse>>(`/Catalogs/${catalogId}/groups`, params)
    );
    if (data) return data.catalogs[0];
  }

  static async saveProductChangesBulk(catalogId: string, bulk: BulkElement[]) {
    const { data } = await requestWrapper(
      axiosBase.put<BaseResponse<{ itemRef: number; succeeded: boolean }[]>>(
        `/Catalogs/${catalogId}/components`,
        bulk
      )
    );
    if (data) return data;
  }

  static async saveDistributionCurves(
    newCurves: EdcProfileDTO[],
    changedCurves: UpdatedCurveDTO[],
    deletedCurves: string[],
    catalogId: string
  ) {
    if (newCurves.length) {
      const { error } = await requestWrapper(axiosBase.post(`/Edc/${catalogId}`, newCurves));
      if (error) return false;
    }
    if (changedCurves.length) {
      const { error } = await requestWrapper(axiosBase.put('Edc', changedCurves));
      if (error) return false;
    }
    if (deletedCurves.length) {
      const params = { data: deletedCurves };
      const { error } = await requestWrapper(axiosBase.delete('Edc', params));
      if (error) return false;
    }

    return true;
  }

  static async saveMovedProductGroups(catalogId: string, dto: MovedProductGroupDTO[]) {
    const { error } = await requestWrapper(axiosBase.put(`/Catalogs/${catalogId}/components/move`, dto));
    if (!error) return true;
  }
}
