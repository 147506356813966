import { Box, Collapse } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { Organization } from 'shared/models';
import { borderDashStyle } from '../OrganizationItem/borderDashStyle';
import { OrgTitle } from './OrgTitle';

interface Props {
  org: Organization;
  depth: number;
  childOrgs?: ReactNode;
  isLastChild?: boolean;
  childIDs: string[];
  currentOrgId: string;
  onSelectOrg: (id: string) => void;
  disabled: boolean;
  isLastOrg: boolean;
}

export const AvailabilityOrgItem: FC<Props> = ({
  org,
  depth,
  childOrgs,
  isLastChild,
  currentOrgId,
  onSelectOrg,
  disabled,
  childIDs,
  isLastOrg,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!depth);
  const handleToggle = () => setIsOpen((prev) => !prev);

  const isLineToLastOrgHidden = isLastChild && isOpen;

  return (
    <Box sx={{ position: 'relative' }}>
      <OrgTitle
        key={org.id}
        org={org}
        depth={depth}
        isOpen={isOpen}
        onToggle={handleToggle}
        currentOrgId={currentOrgId}
        onSelectOrg={onSelectOrg}
        disabled={disabled}
        isLastOrg={isLastOrg}
      />

      <Collapse in={isOpen}>
        <div>{childOrgs}</div>
      </Collapse>

      <Box
        sx={{
          position: 'absolute',
          width: '2px',
          height: 'calc(100% - 38px)',
          top: '19px',
          left: `${depth * 36 + 18}px`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '2px',
            height: '100%',
            top: 0,
            left: 0,
            transform: 'translateX(1px)',
            ...borderDashStyle,
          }}
        />

        {/* hide redundant part of parent's org vertical line if it's last child org is open */}
        {isLineToLastOrgHidden && (
          <Box
            sx={{
              position: 'absolute',
              width: '10px',
              height: 'calc(100%)',
              top: 1,
              right: 29,
              bgcolor: 'white',
              zIndex: 1,
            }}
          >
            {[org.organizationId, ...childIDs].map((id, i) => (
              <Box
                onClick={() => onSelectOrg(id)}
                key={id}
                sx={{
                  height: `${i === 0 ? 18 : 38}px`,
                  width: '100%',
                  borderBottom: '1px solid #D4D4D4',
                  bgcolor: id === currentOrgId ? 'rgba(29, 135, 69, 0.08)' : 'transparent',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
