import { Select, SelectProps } from '@mui/material';
import { FC, useState } from 'react';
import { Icon } from 'shared/ui';

export const AvailabilityTableSelect: FC<SelectProps> = ({ ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ArrowIcon = () => (
    <Icon
      path={'products-view/select-arrow-black'}
      sx={{
        position: 'absolute',
        right: 16,
        pointerEvents: 'none',
        ...(isMenuOpen && { transform: 'rotate(180deg)' }),
      }}
    />
  );

  return (
    <Select
      {...props}
      IconComponent={ArrowIcon}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      sx={{
        height: 35,
        borderRadius: '6px',
        border: '1px solid #DDE0E6',
        background: '#F2F4F7',
        color: '#242731',
        fontWeight: 600,
        overflow: 'hidden',
        '& .MuiSelect-select': {
          py: '6px',
          pl: 2,
          pr: '38px !important',
        },
        fieldset: { display: 'none' },
      }}
    />
  );
};
