import { createAsyncThunk, createDraftSafeSelector, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { ACTION_NAME, CATALOG_TYPE, ERROR_CANCELED, PRODUCT_KEY, SORTING } from 'shared/constants';
import {
  addMissingTableRows,
  getDistributionCurves,
  getFilteredItems,
  getNewEmptyProduct,
  getProductsMap,
  getSearchedCells,
  getTableProducts,
  pasteProductsCopiedCells,
  sortProducts,
  unhandledKeys,
  VALIDATED_KEYS,
} from 'shared/lib';
import {
  ActionType,
  BaseResponse,
  BulkElement,
  Catalog,
  CatalogItemState,
  CellType,
  ConstantFilter,
  EdcProfile,
  EdcProfileDTO,
  FullSpecificationsProductInfo,
  Group,
  MovedProductGroupDTO,
  OpenedCatalogsState,
  TableFilter,
  TableProductInfo,
  UpdatedCurveDTO,
} from 'shared/models';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../hooks';
import { CatalogService } from 'shared/services';
import { AxiosError } from 'axios';
import { axiosBase } from 'shared/axios';
import { emitterColumnsOrder } from 'shared/constants/catalogs/columnOrders';
import { RootState } from 'app/store';

const initialState: OpenedCatalogsState = {
  isLoading: false,
  isEmitterComponentsLoading: false,
  isEdcProfilesLoading: false,
  isSaveLoading: false,
  selectedCells: [],
  openedCatalogs: [],
};

export const getCatalogEmittersThunk = createAsyncThunk(
  'openedCatalogs/getCatalogEmitters',
  async (params: { catalogId: string }, thunkAPI) => {
    const { catalogId } = params;

    try {
      const productsResponse = await axiosBase.get<BaseResponse<{ catalogs: FullSpecificationsProductInfo[] }>>(
        `Catalogs/${catalogId}/Emitter`
      );

      const products = productsResponse.data.data?.catalogs ?? [];

      const productInfos = getTableProducts(products as FullSpecificationsProductInfo[], 'Emitter');

      return { productInfos, catalogId };
    } catch (e) {
      if ((e as AxiosError).code === ERROR_CANCELED) return { message: ERROR_CANCELED };

      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getEdcProfilesThunk = createAsyncThunk(
  'openedCatalogs/getEdcProfiles',
  async (params: { catalogId: string }, thunkAPI) => {
    const { catalogId } = params;

    const state = thunkAPI.getState() as RootState;

    let groups = state.catalogs.groups[catalogId];

    try {
      const productsResponse = await axiosBase.get<BaseResponse<EdcProfile[]>>('Edc');

      const products = productsResponse.data.data ?? [];

      if (!groups) {
        const currentCatalog = await CatalogService.getCurrentCatalog(catalogId);
        groups = currentCatalog?.groups ?? [];
      }

      const curves = getDistributionCurves(products, groups, catalogId);

      return { catalogId, curves };
    } catch (e) {
      if ((e as AxiosError).code === ERROR_CANCELED) return { message: ERROR_CANCELED };

      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getCatalogProductsThunk = createAsyncThunk(
  'openedCatalogs/getCatalogProducts',
  async (
    params: { catalogId: string; type: string; isDistributionCurveType?: boolean; signal?: AbortSignal },
    thunkAPI
  ) => {
    const { catalogId, type, signal } = params;

    // const isDistributionCurveType = type === CATALOG_TYPE.DISTRIBUTION_CURVE;

    try {
      const params = { signal };
      const productsResponse = await axiosBase.get<BaseResponse<{ catalogs: FullSpecificationsProductInfo[] }>>(
        `Catalogs/${catalogId}/${type}`,
        params
      );
      const products = productsResponse.data.data?.catalogs ?? [];

      const productInfos = getTableProducts(products as FullSpecificationsProductInfo[], type);

      // if (isDistributionCurveType) {
      //   const curves = getDistributionCurves(products as FullSpecificationsProductInfo[]);
      //   return { catalogId, loadedType: type, items: curves, emitterItems: productInfos };
      // }

      return { catalogId, loadedType: type, items: productInfos };
    } catch (e) {
      if ((e as AxiosError).code === ERROR_CANCELED) return { message: ERROR_CANCELED };

      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getAllCatalogComponentsThunk = createAsyncThunk(
  'openedCatalogs/getAllCatalogComponents',
  async (params: { catalogId: string; signal?: AbortSignal }, thunkAPI) => {
    const { catalogId, signal } = params;

    try {
      const params = { signal };
      const productsResponse = await axiosBase.get<BaseResponse<{ catalogs: Catalog[] }>>(
        `Catalogs/${catalogId}`,
        params
      );
      const catalogs = productsResponse.data.data?.catalogs ?? [];

      const allProducts = catalogs
        .flatMap((cat) => cat.groups)
        .filter((group) => group.components)
        .flatMap((group) => group.components);

      return { catalogId, items: allProducts };
    } catch (e) {
      if ((e as AxiosError).code === ERROR_CANCELED) return { message: ERROR_CANCELED };

      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const saveCatalogChangesThunk = createAsyncThunk(
  'openedCatalogs/saveCatalogChangesThunk',
  async (params: { catalogId: string; bulk: BulkElement[]; movedProductGroups: MovedProductGroupDTO[] }) => {
    const { catalogId, bulk, movedProductGroups } = params;

    const responseData = await CatalogService.saveProductChangesBulk(catalogId, bulk);
    await CatalogService.saveMovedProductGroups(catalogId, movedProductGroups);

    return { catalogId, responseData: responseData ?? [] };
  }
);

export const saveDistributionCurvesThunk = createAsyncThunk(
  'openedCatalogs/saveDistributionCurvesThunk',
  async (
    params: {
      catalogId: string;
      newCurves: EdcProfileDTO[];
      changedCurves: UpdatedCurveDTO[];
      deletedCurves: string[];
    },
    thunkAPI
  ) => {
    const { catalogId, newCurves, changedCurves, deletedCurves } = params;

    const isSuccess = await CatalogService.saveDistributionCurves(
      newCurves,
      changedCurves,
      deletedCurves,
      catalogId
    );

    if (!isSuccess) return;

    thunkAPI.dispatch(stopEditing(catalogId));

    await thunkAPI.dispatch(getEdcProfilesThunk({ catalogId }));

    return { catalogId };
  }
);

export const openedCatalogsSlice = createSlice({
  name: 'openedCatalogs',
  initialState,
  selectors: {
    selectOpenedCatalogs: (state) => state.openedCatalogs,
    selectOpenedCatalogsLoading: (state) =>
      state.isLoading || state.isEmitterComponentsLoading || state.isEdcProfilesLoading,
    selectSelectedCells: (state) => state.selectedCells,
    selectIsSaveLoading: (state) => state.isSaveLoading,
    selectOpenedCatalogsState: (state) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogEmittersThunk.pending.type, (state) => {
        state.isEmitterComponentsLoading = true;
      })
      .addCase(
        getCatalogEmittersThunk.fulfilled.type,
        (
          state,
          action: PayloadAction<{
            catalogId: string;
            productInfos: TableProductInfo[];
          }>
        ) => {
          const openedCatalog = state.openedCatalogs.find((oc) => oc.catalogId === action.payload.catalogId);
          if (!openedCatalog) return;

          const { productInfos } = action.payload;

          openedCatalog.emitters = [...productInfos];
          state.isEmitterComponentsLoading = false;
        }
      )
      .addCase(getCatalogEmittersThunk.rejected.type, (state, action: PayloadAction<{ message: string }>) => {
        state.isEmitterComponentsLoading = action.payload?.message === ERROR_CANCELED;
      })
      .addCase(getEdcProfilesThunk.pending.type, (state) => {
        state.isEdcProfilesLoading = true;
      })
      .addCase(
        getEdcProfilesThunk.fulfilled.type,
        (
          state,
          action: PayloadAction<{
            catalogId: string;
            curves: TableProductInfo[];
          }>
        ) => {
          const openedCatalog = state.openedCatalogs.find((oc) => oc.catalogId === action.payload.catalogId);
          if (!openedCatalog) return;

          const { curves } = action.payload;
          openedCatalog.edcProfiles = [...curves];

          const allFilters = getCatalogFilters(openedCatalog.tableFilters, openedCatalog.constantFilters);
          const { visibleItems, hidedItems } = getFilteredItems(curves, allFilters, openedCatalog.sorting);

          openedCatalog.visibleItems = getProductsMap(visibleItems);
          openedCatalog.hidedItems = hidedItems;

          state.isEdcProfilesLoading = false;
        }
      )
      .addCase(getEdcProfilesThunk.rejected.type, (state, action: PayloadAction<{ message: string }>) => {
        state.isEdcProfilesLoading = action.payload?.message === ERROR_CANCELED;
      })
      .addCase(getCatalogProductsThunk.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getCatalogProductsThunk.fulfilled.type,
        (
          state,
          action: PayloadAction<{
            catalogId: string;
            loadedType: string;
            items: TableProductInfo[];
            emitterItems?: TableProductInfo[];
          }>
        ) => {
          const { catalogId, loadedType, items, emitterItems } = action.payload;
          if (!catalogId) return;
          const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

          // add constant filters
          if (catalog) {
            const { visibleItems, hidedItems } = getFilteredItems(items, catalog.tableFilters, catalog.sorting);

            catalog.visibleItems = getProductsMap(visibleItems);

            catalog.hidedItems = hidedItems;
            catalog.loadedType = loadedType;

            catalog.saveBulk = [];
            if (emitterItems) {
              catalog.emitterComponents = [...emitterItems];
            }
            state.isLoading = false;
          }
        }
      )
      .addCase(getCatalogProductsThunk.rejected.type, (state, action: PayloadAction<{ message: string }>) => {
        state.isLoading = action.payload?.message === ERROR_CANCELED;
      })
      .addCase(getAllCatalogComponentsThunk.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAllCatalogComponentsThunk.fulfilled.type,
        (
          state,
          action: PayloadAction<{
            catalogId: string;
            items: TableProductInfo[];
          }>
        ) => {
          const { catalogId, items } = action.payload;
          if (!catalogId) return;
          const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

          if (catalog) {
            catalog.allComponents = items;
          }
          state.isLoading = false;
        }
      )
      .addCase(getAllCatalogComponentsThunk.rejected.type, (state, action: PayloadAction<{ message: string }>) => {
        state.isLoading = action.payload?.message === ERROR_CANCELED;
      })
      .addCase(saveCatalogChangesThunk.pending.type, (state) => {
        state.isSaveLoading = true;
      })
      .addCase(
        saveCatalogChangesThunk.fulfilled.type,
        (
          state,
          action: PayloadAction<{ catalogId: string; responseData: { itemRef: number; succeeded: boolean }[] }>
        ) => {
          if (!action.payload || !action?.payload?.catalogId) {
            state.isSaveLoading = false;
            return;
          }

          const { catalogId, responseData } = action.payload;

          const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

          catalog.saveBulk = catalog.saveBulk?.map((bulkItem) => {
            const res = responseData.find((d) => d.itemRef === bulkItem.bulkElement.itemRef);
            return { ...bulkItem, status: res?.succeeded ? 'Succeeded' : 'Failed' };
          });
          state.isSaveLoading = false;
        }
      )
      .addCase(saveCatalogChangesThunk.rejected.type, (state) => {
        state.isSaveLoading = false;
      })
      .addCase(saveDistributionCurvesThunk.pending.type, (state) => {
        state.isSaveLoading = true;
      })
      .addCase(
        saveDistributionCurvesThunk.fulfilled.type,
        (state, action: PayloadAction<{ catalogId: string }>) => {
          if (!action.payload || !action?.payload?.catalogId) {
            state.isSaveLoading = false;
            return;
          }

          const { catalogId } = action.payload;
          const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
          if (catalog) catalog.isEditing = false;

          state.isSaveLoading = false;
        }
      )
      .addCase(saveDistributionCurvesThunk.rejected.type, (state) => {
        state.isSaveLoading = false;
      });
  },
  reducers: {
    resetOpenedCatalogs: () => initialState,
    openCatalog: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      const isAdded = state.openedCatalogs.find((c) => c.catalogId === catalogId);
      if (isAdded) return;

      const catalog: CatalogItemState = {
        catalogId,
        loadedType: '',
        specificationIdx: 0,
        isEditing: false,
        actionHistory: [],
        changedItems: {},
        newItemIDs: {},
        deletedItemIDs: {},
        sorting: { field: PRODUCT_KEY.DESC, value: SORTING.ASC },
        tableFilters: [],
        constantFilters: { subtype: null, group: null, groupName: null },
        isValidating: false,
        cellErrors: {},
        emitterComponents: [],
        edcProfiles: [],
        emitters: [],
        tableSearch: { search: '', currentIdx: 0, cells: [] },
        visibleItems: {},
        hidedItems: [],
        allComponents: [],
        saveBulk: [],
        movedProductGroups: [],
      };
      state.openedCatalogs.push(catalog);
      state.selectedCells = [];
    },
    closeCatalog: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      state.openedCatalogs = state.openedCatalogs.filter((c) => c.catalogId !== catalogId);
      state.selectedCells = [];
    },
    toggleSaveLoading: (state) => {
      state.isSaveLoading = !state.isSaveLoading;
    },
    changeSpecification: (state, action: PayloadAction<{ catalogId: string; index: number }>) => {
      const { catalogId, index } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      catalog.specificationIdx = index;
    },
    setEmptyItems: (state, action: PayloadAction<{ catalogId: string; type: string }>) => {
      const { catalogId, type } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      if (catalog) {
        catalog.isEditing = false;
        catalog.loadedType = type;
        catalog.visibleItems = {};
        catalog.hidedItems = [];
      }
      state.isLoading = false;
    },
    startEditing: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      catalog.isEditing = true;
    },
    stopEditing: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      for (const id in catalog.newItemIDs) {
        delete catalog.visibleItems[id];
      }

      catalog.newItemIDs = {};
      catalog.deletedItemIDs = {};
      catalog.changedItems = {};
      catalog.actionHistory = [];
      catalog.isEditing = false;
    },
    addNewProductItem: (state, action: PayloadAction<{ catalogId: string }>) => {
      const { catalogId } = action.payload;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId);

      if (!catalog) return state;

      const { loadedType } = catalog;
      const newItem: TableProductInfo = getNewEmptyProduct(loadedType);

      catalog.newItemIDs[newItem.id] = true;
      catalog.visibleItems[newItem.id] = newItem;

      const addAction: ActionType = { name: ACTION_NAME.ADD, payload: [newItem] };
      catalog.actionHistory.push(addAction);
    },
    deleteProductItems: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      const cells: CellType[] = current(state.selectedCells);

      const prodIds: string[] = [...new Set(cells.map((c) => c.id))];

      const payload: TableProductInfo[] = [];
      const indexes: number[] = [];
      prodIds.forEach((id) => {
        if (catalog.deletedItemIDs[id]) return;

        const deletedProd = catalog.visibleItems[id];
        if (!deletedProd) return;

        const deletedIdx: number = Object.keys(catalog.visibleItems).findIndex((pId) => pId === id);
        payload.push(deletedProd);
        indexes.push(deletedIdx);

        const isNewItem = catalog.newItemIDs[id];
        if (isNewItem) {
          delete catalog.newItemIDs[id];
          delete catalog.visibleItems[id];
        } else {
          catalog.deletedItemIDs[id] = true;
        }

        if (catalog.changedItems[id]) {
          delete catalog.changedItems[id];
        }

        if (catalog.cellErrors[id]) {
          delete catalog.cellErrors[id];
        }
      });

      const deleteAction: ActionType = { name: ACTION_NAME.DELETE, payload, indexes };
      catalog.actionHistory.push(deleteAction);

      state.selectedCells = [];
    },
    changeProductItemByArrayCell: (state, action: PayloadAction<{ catalogId: string; cells: CellType[] }>) => {
      const { catalogId, cells } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      const id = cells[0].id;

      const alreadyChangedProduct = catalog.changedItems[id];
      const initProject = catalog.visibleItems[id];

      let payloadProduct: TableProductInfo = { id };
      const targetProduct = alreadyChangedProduct ? { ...initProject, ...alreadyChangedProduct } : initProject;
      cells.forEach((c) => {
        const key = c.field as keyof TableProductInfo;
        const value = targetProduct[key];

        payloadProduct = { ...payloadProduct, [key]: value };
      });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload: [payloadProduct] };
      catalog.actionHistory.push(changeAction);

      let changeableProduct = alreadyChangedProduct ?? { id };
      cells.forEach((c) => {
        const key = c.field as keyof TableProductInfo;

        changeableProduct = { ...changeableProduct, [key]: c.value } as TableProductInfo;
      });

      catalog.changedItems[id] = changeableProduct;

      state.selectedCells = [];
    },
    changeProductItem: (state, action: PayloadAction<{ catalogId: string; cell: CellType }>) => {
      const { catalogId, cell } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      const field = cell.field as keyof TableProductInfo;

      const changedProduct = catalog.changedItems[cell.id];

      let oldValue;
      if (changedProduct && field in changedProduct) {
        oldValue = changedProduct[field];
      } else {
        const product = catalog.visibleItems[cell.id];
        oldValue = product && product[field];
      }

      const payloadOldProductValue: TableProductInfo = { id: cell.id, [field]: oldValue };

      const actionChange: ActionType = { name: ACTION_NAME.CHANGE, payload: [payloadOldProductValue] };
      catalog.actionHistory.push(actionChange);

      const isEqualInitValue = catalog.visibleItems[cell.id]?.[field] === cell.value;

      if (isEqualInitValue) {
        catalog.changedItems[cell.id]?.[field] && delete catalog.changedItems[cell.id][field];
        const isNoChanges = Object.keys(catalog.changedItems?.[cell.id] ?? {})?.length == 1;

        if (isNoChanges) {
          delete catalog.changedItems[cell.id];
        }
      } else {
        catalog.changedItems[cell.id] = changedProduct
          ? { ...changedProduct, [field]: cell.value }
          : { id: cell.id, [field]: cell.value };
      }
    },
    selectCells: (state, action: PayloadAction<CellType[]>) => {
      const cells = action.payload;
      state.selectedCells = cells;
    },
    pasteCopiedCells: (state, action: PayloadAction<{ catalogId: string; cells: CellType[] }>) => {
      const { catalogId, cells: copiedCells } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const selectedCells: CellType[] = current(state.selectedCells);
      if (!copiedCells.length || !selectedCells.length) return;

      const items = current(catalog.visibleItems);
      const itemIds: string[] = Object.keys(items);

      const columnKeys =
        catalog.loadedType === CATALOG_TYPE.EMITTER
          ? emitterColumnsOrder
          : Object.keys(items[itemIds[0]]).filter((k) => !unhandledKeys.includes(k));

      const copiedRowsNumber = new Set(copiedCells.map((cell) => cell.id)).size;
      const selectedRowsNumber = new Set(selectedCells.map((cell) => cell.id)).size;
      const copiedRowCellsNumber = new Set(copiedCells.map((cell) => cell.field)).size;

      const firstPasteCell = selectedCells[0];
      const firstPasteColumn = columnKeys.findIndex((column) => column === firstPasteCell.field);
      const startPasteIdx = itemIds.findIndex((id) => id === firstPasteCell.id);

      const areCopiedMultipleRows = copiedRowsNumber > 1;
      // add new rows
      if (areCopiedMultipleRows) {
        const rowsCount = itemIds.length;
        const { newItemIDs, visibleItems } = addMissingTableRows(
          catalog,
          copiedRowsNumber,
          rowsCount,
          startPasteIdx
        );

        catalog.newItemIDs = { ...catalog.newItemIDs, ...newItemIDs };
        catalog.visibleItems = { ...catalog.visibleItems, ...visibleItems };
      }

      const filteredTableItems = Object.values(catalog.visibleItems).filter(
        (item) => !catalog.deletedItemIDs[item.id]
      );
      const updatedItems = filteredTableItems.slice(
        startPasteIdx,
        startPasteIdx + (areCopiedMultipleRows ? copiedRowsNumber : selectedRowsNumber)
      );

      const updatedKeys = columnKeys.slice(firstPasteColumn, firstPasteColumn + copiedRowCellsNumber);

      const { updatedProducts, changedItems } = pasteProductsCopiedCells(
        catalog,
        updatedItems,
        copiedCells,
        updatedKeys,
        areCopiedMultipleRows
      );

      catalog.changedItems = { ...catalog.changedItems, ...changedItems };

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload: updatedProducts };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    clearSelectedCells: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      const cells: CellType[] = current(state.selectedCells);

      const selectedIds: string[] = [...new Set(cells.map((c) => c.id))];

      const firstCellsID = selectedIds[0];
      const selectedColumns = cells.filter((c) => c.id === firstCellsID).map((c) => c.field);

      const payload: TableProductInfo[] = Object.keys(catalog.visibleItems)
        .filter((id) => selectedIds.includes(id) && !catalog.deletedItemIDs[id])
        .map((id) => {
          const p = catalog.visibleItems[id];
          const alreadyChangedProduct = catalog.changedItems[p.id];

          const targetProduct = alreadyChangedProduct ? { ...p, ...alreadyChangedProduct } : p;
          let payloadProduct: TableProductInfo = { id: p.id };
          selectedColumns.forEach((c) => {
            const key = c as keyof TableProductInfo;
            const value = targetProduct[key];

            payloadProduct = { ...payloadProduct, [key]: value };
          });

          let changeableProduct = alreadyChangedProduct ?? { id: p.id };
          selectedColumns.forEach((c) => {
            const key = c as keyof TableProductInfo;

            changeableProduct = { ...changeableProduct, [key]: null };
          });

          catalog.changedItems[p.id] = changeableProduct;

          return payloadProduct;
        });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    pasteToColumnUp: (state, action: PayloadAction<{ catalogId: string; groups: Group[] }>) => {
      const { catalogId, groups } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const cells: CellType[] = current(state.selectedCells);
      const items = current(catalog.visibleItems);
      const itemIds = Object.keys(items);

      const selectedIds: string[] = [...new Set(cells.map((c) => c.id))];

      let bottomSelectedIdx = 0;
      selectedIds.forEach((id) => {
        const index = itemIds.findIndex((pId) => pId === id);
        if (index > bottomSelectedIdx) {
          bottomSelectedIdx = index;
        }
      });

      const bottomSelectedCells = cells.filter((c) => c.id === itemIds[bottomSelectedIdx]);

      const payload: TableProductInfo[] = Object.values(catalog.visibleItems)
        .filter(({ id }, idx) => idx < bottomSelectedIdx && !catalog.deletedItemIDs[id])
        .map((p) => {
          const alreadyChangedProduct = catalog.changedItems[p.id];

          //payload for action history
          let payloadProduct: TableProductInfo = { id: p.id };
          const targetProduct = alreadyChangedProduct ? { ...p, ...alreadyChangedProduct } : p;
          bottomSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;
            const value = targetProduct[key];

            payloadProduct = { ...payloadProduct, [key]: value };
          });

          let changeableProduct = alreadyChangedProduct ?? { id: p.id };
          bottomSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;

            if (key === PRODUCT_KEY.GROUP) {
              const copiedGroupSubtype = groups.find((g) => g.name === c.value)?.subtype?.name;

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.SUBTYPE]: copiedGroupSubtype,
              } as TableProductInfo;
            } else if (key === PRODUCT_KEY.GROUP_NAME) {
              const copiedGroup = groups.find((g) => g.name === c.value);

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.GROUP_ID]: copiedGroup?.id,
                [PRODUCT_KEY.SUBTYPE]: copiedGroup?.subtype?.name,
              } as TableProductInfo;
            } else {
              changeableProduct = { ...changeableProduct, [key]: c.value } as TableProductInfo;
            }
          });

          const changedCells: PRODUCT_KEY[] = Object.keys(payloadProduct).filter(
            (k): k is PRODUCT_KEY => k !== PRODUCT_KEY.ID
          );

          changedCells.forEach((cell) => {
            if (VALIDATED_KEYS.includes(cell)) {
              const copiedCellId = bottomSelectedCells.find((c) => c.field === cell)?.id || '';
              const isCellError = catalog.cellErrors[copiedCellId]?.[cell];

              if (isCellError) {
                if (catalog.cellErrors[changeableProduct.id]) {
                  catalog.cellErrors[changeableProduct.id][cell] = true;
                } else {
                  catalog.cellErrors[changeableProduct.id] = { [cell]: true };
                }
              } else if (catalog.cellErrors[changeableProduct.id]?.[cell]) {
                delete catalog.cellErrors[changeableProduct.id][cell];
              }
            }
          });

          catalog.changedItems[p.id] = changeableProduct;

          return payloadProduct;
        });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    pasteToColumnDown: (state, action: PayloadAction<{ catalogId: string; groups: Group[] }>) => {
      const { catalogId, groups } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const cells: CellType[] = current(state.selectedCells);
      const items = current(catalog.visibleItems);
      const itemIds = Object.keys(items);

      const selectedIds: string[] = [...new Set(cells.map((c) => c.id))];

      let topSelectedIdx = itemIds.length - 1;
      selectedIds.forEach((id) => {
        const index = itemIds.findIndex((pId) => pId === id);
        if (index < topSelectedIdx) {
          topSelectedIdx = index;
        }
      });

      const topSelectedCells = cells.filter((c) => c.id === itemIds[topSelectedIdx]);

      const payload: TableProductInfo[] = Object.values(catalog.visibleItems)
        .filter(({ id }, idx) => {
          return idx > topSelectedIdx && !catalog.deletedItemIDs[id];
        })
        .map((p) => {
          const alreadyChangedProduct = catalog.changedItems[p.id];

          const targetProduct = alreadyChangedProduct ? { ...p, ...alreadyChangedProduct } : p;
          const payloadProduct: TableProductInfo = topSelectedCells.reduce(
            (obj, c) => {
              const key = c.field as keyof TableProductInfo;
              const value = targetProduct[key];
              return { ...obj, [key]: value } as TableProductInfo;
            },
            { id: p.id }
          );

          const changeableProduct = topSelectedCells.reduce(
            (obj, cell) => {
              const key = cell.field as keyof TableProductInfo;

              if (key === PRODUCT_KEY.GROUP) {
                const copiedGroupSubtype = groups.find((g) => g.name === cell.value)?.subtype?.name;

                return {
                  ...obj,
                  [key]: cell.value,
                  [PRODUCT_KEY.SUBTYPE]: copiedGroupSubtype,
                } as TableProductInfo;
              }

              if (key === PRODUCT_KEY.GROUP_NAME) {
                const copiedGroup = groups.find((g) => g.name === cell.value);

                return {
                  ...obj,
                  [key]: cell.value,
                  [PRODUCT_KEY.GROUP_ID]: copiedGroup?.id,
                  [PRODUCT_KEY.SUBTYPE]: copiedGroup?.subtype?.name,
                } as TableProductInfo;
              }

              return { ...obj, [key]: cell.value } as TableProductInfo;
            },
            alreadyChangedProduct ?? { id: p.id }
          );

          const changedCells: PRODUCT_KEY[] = Object.keys(payloadProduct).filter(
            (k): k is PRODUCT_KEY => k !== PRODUCT_KEY.ID
          );

          changedCells.forEach((cell) => {
            if (VALIDATED_KEYS.includes(cell)) {
              const copiedCellId = topSelectedCells.find((c) => c.field === cell)?.id || '';
              const isCellError = catalog.cellErrors[copiedCellId]?.[cell];

              if (isCellError) {
                if (catalog.cellErrors[changeableProduct.id]) {
                  catalog.cellErrors[changeableProduct.id][cell] = true;
                } else {
                  catalog.cellErrors[changeableProduct.id] = { [cell]: true };
                }
              } else if (catalog.cellErrors[changeableProduct.id]?.[cell]) {
                delete catalog.cellErrors[changeableProduct.id][cell];
              }
            }
          });

          catalog.changedItems[p.id] = changeableProduct;

          return payloadProduct;
        });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    pasteToSelectionDown: (state, action: PayloadAction<{ catalogId: string; groups: Group[] }>) => {
      const { catalogId, groups } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const cells: CellType[] = current(state.selectedCells);
      const items = current(catalog.visibleItems);
      const itemIds = Object.keys(items);

      const selectedIds: string[] = [...new Set(cells.map((c) => c.id))];

      let topSelectedIdx = itemIds.length - 1;
      let bottomSelectedIdx = 0;
      selectedIds.forEach((id) => {
        const index = itemIds.findIndex((pId) => pId === id);
        if (index < topSelectedIdx) {
          topSelectedIdx = index;
        }
        if (index > bottomSelectedIdx) {
          bottomSelectedIdx = index;
        }
      });

      const topSelectedCells = cells.filter((c) => c.id === itemIds[topSelectedIdx]);

      const payload: TableProductInfo[] = Object.values(catalog.visibleItems)
        .filter(({ id }, idx) => idx > topSelectedIdx && idx <= bottomSelectedIdx && !catalog.deletedItemIDs[id])
        .map((p) => {
          const alreadyChangedProduct = catalog.changedItems[p.id];

          let payloadProduct: TableProductInfo = { id: p.id };
          const targetProduct = alreadyChangedProduct ? { ...p, ...alreadyChangedProduct } : p;
          topSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;
            const value = targetProduct[key];

            payloadProduct = { ...payloadProduct, [key]: value };
          });

          let changeableProduct = alreadyChangedProduct ?? { id: p.id };
          topSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;

            if (key === PRODUCT_KEY.GROUP) {
              const copiedGroupSubtype = groups.find((g) => g.name === c.value)?.subtype?.name;

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.SUBTYPE]: copiedGroupSubtype,
              } as TableProductInfo;
            } else if (key === PRODUCT_KEY.GROUP_NAME) {
              const copiedGroup = groups.find((g) => g.name === c.value);

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.GROUP_ID]: copiedGroup?.id,
                [PRODUCT_KEY.SUBTYPE]: copiedGroup?.subtype?.name,
              } as TableProductInfo;
            } else {
              changeableProduct = { ...changeableProduct, [key]: c.value } as TableProductInfo;
            }
          });

          const changedCells: PRODUCT_KEY[] = Object.keys(payloadProduct).filter(
            (k): k is PRODUCT_KEY => k !== PRODUCT_KEY.ID
          );

          changedCells.forEach((cell) => {
            if (VALIDATED_KEYS.includes(cell)) {
              const copiedCellId = topSelectedCells.find((c) => c.field === cell)?.id || '';
              const isCellError = catalog.cellErrors[copiedCellId]?.[cell];

              if (isCellError) {
                if (catalog.cellErrors[changeableProduct.id]) {
                  catalog.cellErrors[changeableProduct.id][cell] = true;
                } else {
                  catalog.cellErrors[changeableProduct.id] = { [cell]: true };
                }
              } else if (catalog.cellErrors[changeableProduct.id]?.[cell]) {
                delete catalog.cellErrors[changeableProduct.id][cell];
              }
            }
          });

          catalog.changedItems[p.id] = changeableProduct;

          return payloadProduct;
        });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    pasteToSelectionUp: (state, action: PayloadAction<{ catalogId: string; groups: Group[] }>) => {
      const { catalogId, groups } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const cells: CellType[] = current(state.selectedCells);
      const items = current(catalog.visibleItems);
      const itemIds = Object.keys(items);

      const selectedIds: string[] = [...new Set(cells.map((c) => c.id))];

      let topSelectedIdx = itemIds.length - 1;
      let bottomSelectedIdx = 0;
      selectedIds.forEach((id) => {
        const index = itemIds.findIndex((pId) => pId === id);
        if (index < topSelectedIdx) {
          topSelectedIdx = index;
        }
        if (index > bottomSelectedIdx) {
          bottomSelectedIdx = index;
        }
      });

      const bottomSelectedCells = cells.filter((c) => c.id === itemIds[bottomSelectedIdx]);

      const payload: TableProductInfo[] = Object.values(catalog.visibleItems)
        .filter(({ id }, idx) => idx >= topSelectedIdx && idx < bottomSelectedIdx && !catalog.deletedItemIDs[id])
        .map((p) => {
          const alreadyChangedProduct = catalog.changedItems[p.id];

          const targetProduct = alreadyChangedProduct ? { ...p, ...alreadyChangedProduct } : p;
          let payloadProduct: TableProductInfo = { id: p.id };
          bottomSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;
            const value = targetProduct[key];

            payloadProduct = { ...payloadProduct, [key]: value };
          });

          let changeableProduct = alreadyChangedProduct ?? { id: p.id };

          bottomSelectedCells.forEach((c) => {
            const key = c.field as keyof TableProductInfo;

            if (key === PRODUCT_KEY.GROUP) {
              const copiedGroupSubtype = groups.find((g) => g.name === c.value)?.subtype?.name;

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.SUBTYPE]: copiedGroupSubtype,
              } as TableProductInfo;
            } else if (key === PRODUCT_KEY.GROUP_NAME) {
              const copiedGroup = groups.find((g) => g.name === c.value);

              changeableProduct = {
                ...changeableProduct,
                [key]: c.value,
                [PRODUCT_KEY.GROUP_ID]: copiedGroup?.id,
                [PRODUCT_KEY.SUBTYPE]: copiedGroup?.subtype?.name,
              } as TableProductInfo;
            } else {
              changeableProduct = { ...changeableProduct, [key]: c.value };
            }
          });

          const changedCells: PRODUCT_KEY[] = Object.keys(payloadProduct).filter(
            (k): k is PRODUCT_KEY => k !== PRODUCT_KEY.ID
          );

          changedCells.forEach((cell) => {
            if (VALIDATED_KEYS.includes(cell)) {
              const copiedCellId = bottomSelectedCells.find((c) => c.field === cell)?.id || '';
              const isCellError = catalog.cellErrors[copiedCellId]?.[cell];

              if (isCellError) {
                if (catalog.cellErrors[changeableProduct.id]) {
                  catalog.cellErrors[changeableProduct.id][cell] = true;
                } else {
                  catalog.cellErrors[changeableProduct.id] = { [cell]: true };
                }
              } else if (catalog.cellErrors[changeableProduct.id]?.[cell]) {
                delete catalog.cellErrors[changeableProduct.id][cell];
              }
            }
          });

          catalog.changedItems[p.id] = changeableProduct;

          return payloadProduct;
        });

      const changeAction: ActionType = { name: ACTION_NAME.CHANGE, payload };
      catalog.actionHistory.push(changeAction);

      state.selectedCells = [];
    },
    undoProductAction: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const history: ActionType[] = current(catalog.actionHistory);

      if (history.length === 0) return;
      const lastAction = history.at(-1);
      catalog.actionHistory = catalog.actionHistory.filter((_, i) => i !== history.length - 1);

      if (!lastAction) return;

      const { name, payload, indexes } = lastAction;

      switch (name) {
        case ACTION_NAME.DELETE:
          payload.forEach((p, i) => {
            if (catalog.deletedItemIDs[p.id]) {
              delete catalog.deletedItemIDs[p.id];
            } else {
              const productIdx = (indexes as number[])[i];

              const entries = Object.entries(catalog.visibleItems);

              entries.splice(productIdx, 0, [p.id, p]);

              catalog.visibleItems = Object.fromEntries(entries);
              catalog.newItemIDs[p.id] = true;
            }
          });
          break;

        case ACTION_NAME.ADD:
          {
            const addIds = payload.map((p) => p.id);
            addIds.forEach((id) => {
              delete catalog.newItemIDs[id];
              delete catalog.visibleItems[id];
            });
          }
          break;

        case ACTION_NAME.CHANGE:
          {
            const changeIds = payload.map((p) => p.id);

            changeIds.forEach((id) => {
              let currentChangedProductInfo = catalog.changedItems[id];

              const initProduct = catalog.visibleItems[id];
              const prevProductInfo = payload.find((prod) => prod.id === id) as TableProductInfo;
              const columnKeys = Object.keys(prevProductInfo).filter((key) => key !== PRODUCT_KEY.ID);

              columnKeys.forEach((k) => {
                const key = k as keyof TableProductInfo;
                currentChangedProductInfo = { ...currentChangedProductInfo, [key]: prevProductInfo[key] };

                if (initProduct?.[key] === currentChangedProductInfo?.[key]) {
                  delete currentChangedProductInfo[key];
                }
              });

              const keys = Object.keys(currentChangedProductInfo);
              const noChanges = keys.filter((key) => key !== PRODUCT_KEY.ID).length === 0;

              if (noChanges) {
                delete catalog.changedItems[id];
              } else {
                catalog.changedItems[id] = currentChangedProductInfo;
              }
            });
          }

          break;
      }

      state.selectedCells = [];
    },
    setCatalogSorting: (state, action: PayloadAction<{ catalogId: string; field: string; sorting: SORTING }>) => {
      const { catalogId, field, sorting } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      catalog.sorting = { field, value: sorting };

      const items = Object.values(catalog.visibleItems);

      const sortedItems = items.sort((p1, p2) => sortProducts(p1, p2, field, sorting));

      catalog.visibleItems = getProductsMap(sortedItems);

      state.selectedCells = [];
    },
    setCatalogFilters: (state, action: PayloadAction<{ catalogId: string; filters: TableFilter[] }>) => {
      const { catalogId, filters } = action.payload;
      if (!catalogId) return;

      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId);
      if (!catalog) return;

      catalog.tableFilters = filters;

      // include constant filter options in filtering
      const allFilters = getCatalogFilters(catalog.tableFilters, catalog.constantFilters);
      const items = [...Object.values(catalog.visibleItems), ...catalog.hidedItems];
      const { visibleItems, hidedItems } = getFilteredItems(items, allFilters, catalog.sorting);

      catalog.visibleItems = getProductsMap(visibleItems);
      catalog.hidedItems = hidedItems;

      state.selectedCells = [];
    },
    setCatalogConstantFilters: (
      state,
      action: PayloadAction<{ catalogId: string; filters: Partial<ConstantFilter> }>
    ) => {
      const { catalogId, filters } = action.payload;
      if (!catalogId) return;

      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId);
      if (!catalog) return;

      catalog.constantFilters = { ...catalog.constantFilters, ...filters };

      // include table filters in filtering
      const allFilters = getCatalogFilters(catalog.tableFilters, catalog.constantFilters);
      const items = [...Object.values(catalog.visibleItems), ...catalog.hidedItems];
      const { visibleItems, hidedItems } = getFilteredItems(items, allFilters, catalog.sorting);

      catalog.visibleItems = getProductsMap(visibleItems);
      catalog.hidedItems = hidedItems;

      state.selectedCells = [];
    },
    addCellError: (state, action: PayloadAction<{ catalogId: string; cell: CellType }>) => {
      const { catalogId, cell } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      if (catalog.cellErrors[cell.id]) {
        catalog.cellErrors[cell.id][cell.field] = true;
      } else {
        catalog.cellErrors[cell.id] = { [cell.field]: true };
      }
    },
    removeCellError: (state, action: PayloadAction<{ catalogId: string; cell: CellType }>) => {
      const { catalogId, cell } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const errorsObj = catalog.cellErrors?.[cell.id] ?? {};
      if (errorsObj?.[cell.field]) {
        delete errorsObj[cell.field];
      }
      if (!Object.keys(errorsObj).length) {
        delete catalog.cellErrors[cell.id];
      }
    },
    setCellErrors: (
      state,
      action: PayloadAction<{ catalogId: string; cellErrors: { [id: string]: { [key: string]: boolean } } }>
    ) => {
      const { catalogId, cellErrors } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      catalog.cellErrors = cellErrors;
    },
    clearCellErrors: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      catalog.cellErrors = {};
    },
    toggleValidating: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;
      catalog.isValidating = !catalog.isValidating;
    },
    setSaveBulk: (
      state,
      action: PayloadAction<{
        catalogId: string;
        bulk: { bulkElement: BulkElement; item: TableProductInfo }[];
        movedProductGroups: MovedProductGroupDTO[];
      }>
    ) => {
      const { catalogId, bulk, movedProductGroups } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      catalog.saveBulk = [...bulk];
      catalog.movedProductGroups = [...movedProductGroups];
    },
    setProductsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTableSearch: (state, action: PayloadAction<{ catalogId: string; value: string }>) => {
      const { catalogId, value } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const search = value.trim();

      const items = Object.values(catalog.visibleItems);
      const cells: CellType[] = getSearchedCells(search, items);

      catalog.tableSearch = { search, currentIdx: 0, cells };
    },
    setTableSearchCurrentIdx: (state, action: PayloadAction<{ catalogId: string; moveTo: 'next' | 'prev' }>) => {
      const { catalogId, moveTo } = action.payload;
      if (!catalogId) return;
      const catalog = state.openedCatalogs.find((c) => c.catalogId === catalogId) as CatalogItemState;

      const { currentIdx, cells } = catalog.tableSearch;

      const nextIdx = currentIdx === cells.length - 1 ? 0 : currentIdx + 1;
      const prevIdx = currentIdx === 0 ? cells.length - 1 : currentIdx - 1;

      catalog.tableSearch.currentIdx = moveTo === 'next' ? nextIdx : prevIdx;
    },
  },
});

export const {
  openCatalog,
  closeCatalog,
  changeSpecification,
  startEditing,
  stopEditing,
  setEmptyItems,
  selectCells,
  addNewProductItem,
  deleteProductItems,
  clearSelectedCells,
  changeProductItem,
  pasteCopiedCells,
  pasteToColumnUp,
  pasteToColumnDown,
  pasteToSelectionUp,
  pasteToSelectionDown,
  undoProductAction,
  resetOpenedCatalogs,
  setCatalogSorting,
  setCatalogFilters,
  setCatalogConstantFilters,
  addCellError,
  removeCellError,
  clearCellErrors,
  toggleValidating,
  setCellErrors,
  toggleSaveLoading,
  setSaveBulk,
  setProductsLoading,
  setTableSearch,
  setTableSearchCurrentIdx,
  changeProductItemByArrayCell,
} = openedCatalogsSlice.actions;

export const {
  selectOpenedCatalogsState,
  selectOpenedCatalogs,
  selectOpenedCatalogsLoading,
  selectSelectedCells,
  selectIsSaveLoading,
} = openedCatalogsSlice.selectors;

export const memoizedSelectOpenedCatalogById = (catalogId: string) =>
  createDraftSafeSelector(
    selectOpenedCatalogs,
    (catalogs) => catalogs.find((cat) => cat.catalogId === catalogId),
    { memoizeOptions: { resultEqualityCheck: shallowEqual } }
  );

export const selectOpenedCatalogById = (catalogId: string) =>
  useAppSelector(memoizedSelectOpenedCatalogById(catalogId));

export const openedCatalogsReducer = openedCatalogsSlice.reducer;

const getCatalogFilters = (tableFilters: TableFilter[], constantFilters: ConstantFilter) => {
  const constantFiltersArray = Object.entries(constantFilters)
    .filter(([_, value]) => value)
    .map(([key, value]) => ({ field: key, values: [value] }) as TableFilter);

  return [...tableFilters, ...constantFiltersArray];
};

const getPasteColumnKey = (
  idx: number,
  isPastingEntireProduct: boolean,
  columnKeys: string[],
  pointCol: string
) => {
  if (isPastingEntireProduct) {
    return columnKeys[idx] as keyof TableProductInfo;
  }

  const firstColIdx = columnKeys.indexOf(pointCol);
  return columnKeys[firstColIdx + idx] as keyof TableProductInfo;
};
