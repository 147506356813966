import { FC, useEffect, useLayoutEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { ChangeProductsControl, NoGroupsModal, TableHeader } from './components';
import { useAppDispatch, useAppSelector, useCatalogsControl, useCatalogSearchParams } from 'shared/hooks';
import { CatalogFinalUpdates, CatalogInfo, CatalogSavingStatus } from 'features';
import { Loader } from 'shared/ui';
import { toast } from 'react-toastify';
import { GridContainer } from './GridContainer';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  addNewProductItem,
  selectShouldUpdateCatalogProducts,
  setShouldUpdateCatalogProducts,
} from 'shared/slices';
import { CatalogConstantFilters } from 'features/CatalogConstantFilters';

export const CatalogProducts: FC<{ isGroupsLoading: boolean }> = ({ isGroupsLoading }) => {
  const { catalogId, type } = useCatalogSearchParams();
  const tableRef = useGridApiRef();

  const dispatch = useAppDispatch();

  const {
    loadedType,
    specification,
    items,
    hidedItems,
    changedItems,
    isEditing,
    deletedItemIDs,
    newItemIDs,
    catalogTypeGroups,
    catalogBrands,
    catalogTypeSubtypes,
    cellErrors,
    isValidating,
    isDistributionCurveType,
    tableFilters,
    sorting,
    isLoading,
    isEmitterComponentsLoading,
    isSaveLoading,
    isCatalogInfoLoaded,
    tableSearch,
    saveBulk,
    movedProductGroups,
    actionHistory,
    onToggleValidating,
    getProducts,
    onSetEmptyProductList,
    onChangeFilters,
    allComponents,
    emitters,
    constantFilters,
  } = useCatalogsControl();

  const shouldUpdateCatalogProducts = useAppSelector(selectShouldUpdateCatalogProducts);

  const isNoGroups = !!isCatalogInfoLoaded && !catalogTypeGroups.length && !!loadedType;
  const isLoaderShown = isLoading || isGroupsLoading || isEmitterComponentsLoading;

  const handleHorizontalScroll = (side: 'right' | 'left') => {
    let left = tableRef.current.getScrollPosition().left;
    const offset = side === 'right' ? 50 : -50;
    left += offset;
    tableRef.current.scroll({ left });
  };

  const handleAddTableItem = () => {
    catalogId && dispatch(addNewProductItem({ catalogId }));
    if (isEditing) {
      setTimeout(() => {
        const rowIndex = tableRef.current?.getRowsCount() - 1;
        tableRef.current?.scrollToIndexes({ rowIndex });
      }, 0);
    }
  };

  const handleGetCatalogProducts = (signal: AbortSignal) => {
    if (isNoGroups) {
      onSetEmptyProductList();
      return;
    }

    if (loadedType === type && !shouldUpdateCatalogProducts) return;

    try {
      getProducts(type, signal);
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      dispatch(setShouldUpdateCatalogProducts(false));
    }
  };

  useLayoutEffect(() => {
    const abortController = new AbortController();

    handleGetCatalogProducts(abortController.signal);

    return () => abortController.abort();
  }, [catalogId, type]);

  useEffect(() => {
    // reset filters on catalog table page unmount
    return () => {
      onChangeFilters([]);
    };
  }, []);

  useEffect(() => {
    onChangeFilters([]);
  }, [type]);

  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      <CatalogInfo isEditing={isEditing} />

      <CatalogConstantFilters
        filters={constantFilters}
        groupItems={catalogTypeGroups}
        subtypeItems={catalogTypeSubtypes}
        isEditing={isEditing}
        isSaveLoading={isSaveLoading}
      />

      {isLoaderShown ? (
        <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Stack sx={{ position: 'relative', flexGrow: 1 }}>
            {isNoGroups && <NoGroupsModal />}
            <TableHeader
              isEditing={isEditing}
              tableSearch={tableSearch}
              filters={tableFilters}
              onChangeFilters={onChangeFilters}
            />

            <GridContainer
              tableRef={tableRef}
              type={type}
              specification={specification}
              tableFilters={tableFilters}
              sorting={sorting}
              constantFilters={constantFilters}
              items={items}
              hidedItems={hidedItems}
              changedItems={changedItems}
              isEditing={isEditing}
              deletedItemIDs={deletedItemIDs}
              newItemIDs={newItemIDs}
              tableSearch={tableSearch}
              isDistributionCurveType={isDistributionCurveType}
              catalogTypeGroups={catalogTypeGroups}
              catalogBrands={catalogBrands}
              catalogTypeSubtypes={catalogTypeSubtypes}
              cellErrors={cellErrors}
              isValidating={isValidating}
              onToggleValidating={onToggleValidating}
              catalogId={catalogId}
              allComponents={allComponents}
              emitters={emitters}
            />

            <CatalogFinalUpdates
              type={loadedType}
              items={items}
              deletedItemIDs={deletedItemIDs}
              newItemIDs={newItemIDs}
              changedItems={changedItems}
              isDistributionCurveType={isDistributionCurveType}
              actionHistory={actionHistory}
              cellErrors={cellErrors}
              groups={catalogTypeGroups}
              brands={catalogBrands}
              allComponents={allComponents}
            />
            <CatalogSavingStatus
              saveBulk={saveBulk}
              movedProductGroups={movedProductGroups}
              getProducts={getProducts}
            />
            {isSaveLoading && <Box sx={{ position: 'absolute', inset: 0, zIndex: 2 }} />}
          </Stack>

          <ChangeProductsControl
            type={type}
            specification={specification}
            disabled={isSaveLoading}
            items={items}
            changedItems={changedItems}
            isEditing={isEditing}
            deletedItemIDs={deletedItemIDs}
            newItemIDs={newItemIDs}
            isDistributionCurveType={isDistributionCurveType}
            isSaveLoading={isSaveLoading}
            cellErrors={cellErrors}
            groups={catalogTypeGroups}
            brands={catalogBrands}
            onAddItem={handleAddTableItem}
            handleHorizontalScroll={handleHorizontalScroll}
          />
        </>
      )}
    </Stack>
  );
};
