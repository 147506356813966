import { FC, PropsWithChildren, useEffect } from 'react';
import { axiosBase } from 'shared/axios';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { mapPermissions } from 'shared/lib/permissions/mapPermissions';
import {
  setAllRoles,
  setOrganizations,
  setPermissions,
  setPermissionsLoading,
  setToken,
  setUserRoles,
} from 'shared/slices';
import { PermissionsLoadingScreen } from 'widgets';
import { toast } from 'react-toastify';
import { OrgService } from 'shared/services';

export const PermissionsProcess: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      dispatch(setToken(token));
    }
  }, []);

  useEffect(() => {
    const getPermissions = async () => {
      dispatch(setPermissionsLoading(true));

      try {
        const rolesRequest = axiosBase.get('PermitIO/roles');
        const organizationsRequest = OrgService.getOrgs();
        const userRolesRequest = axiosBase.get('/Auth/me');

        const [rolesResponse, organizations, userRolesResponse] = await Promise.all([
          rolesRequest,
          organizationsRequest,
          userRolesRequest,
        ]);

        if (!organizations?.length) return;

        const permissions = mapPermissions(organizations);

        dispatch(setPermissions(permissions));
        dispatch(setAllRoles(rolesResponse.data['data']));
        dispatch(setUserRoles(userRolesResponse.data['data'].permitRoles));
        dispatch(setOrganizations(organizations));
      } catch (error) {
        dispatch(setPermissionsLoading(false));
        toast.error('Something went wrong.');
      } finally {
        dispatch(setPermissionsLoading(false));
      }
    };

    if (!token) return;

    getPermissions();
  }, [token]);

  // useEffect(() => {
  //   const isOnlyRapidPermission = checkOnlyRapidPermission(userRoles);
  //
  //   if (isOnlyRapidPermission) {
  //     navigateToRapidApp();
  //   }
  // }, [userRoles]);

  return <PermissionsLoadingScreen>{children}</PermissionsLoadingScreen>;
};
