import { Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { AvailableGroup } from 'shared/models';
import { HoverToolTip, Icon } from 'shared/ui';

interface Props {
  group: AvailableGroup;
  isChecked: boolean;
  isSelectedForView: boolean;
  productsAmountInfo: string;
  onSelectForView: () => void;
  onToggle: () => void;
  isLoading: boolean;
}

const checkboxControl = (
  <Checkbox
    icon={<Icon path="checkbox-green-icon" />}
    checkedIcon={<Icon path="checkbox-green-icon-checked" />}
    sx={{ mr: 0.5, width: 16, height: 16 }}
  />
);

export const AvailabilityGroup: FC<Props> = ({
  group,
  isChecked,
  isSelectedForView,
  productsAmountInfo,
  onSelectForView,
  onToggle,
  isLoading,
}) => {
  return (
    <Stack
      onClick={onSelectForView}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      sx={{
        py: 1,
        pr: 1.5,
        pl: 1.5,
        height: 38,
        ...(isSelectedForView && { bgcolor: 'rgba(29, 135, 69, 0.08) !important' }),
        '&:not(:last-child)': { borderBottom: '0.5px solid #D4D4D4' },
        '&:hover': { bgcolor: 'rgba(29, 135, 69, 0.04)' },
      }}
    >
      <FormControlLabel
        checked={isChecked}
        onChange={onToggle}
        label={
          <Typography noWrap sx={{ color: '#242731' }}>
            <HoverToolTip title={group.name} placement="top-start">
              <span>{group.name}</span>
            </HoverToolTip>
          </Typography>
        }
        control={checkboxControl}
        sx={{ m: 0 }}
      />

      <Typography noWrap sx={{ fontWeight: 600, color: '#242731' }}>
        {isLoading ? (
          <CircularProgress size={15} color="primary" sx={{ m: '2px' }} />
        ) : (
          <HoverToolTip title={productsAmountInfo} placement="top-start">
            <span>{productsAmountInfo}</span>
          </HoverToolTip>
        )}
      </Typography>
    </Stack>
  );
};
