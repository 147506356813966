import { Box, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { selectAuthToken, selectPermissionsLoading } from '../../shared/slices';
import { Loader } from 'shared/ui';

export const PermissionsLoadingScreen: FC<PropsWithChildren> = ({ children }) => {
  const { palette } = useTheme();
  const location = useLocation();
  const token = useAppSelector(selectAuthToken);
  const isLoading = useAppSelector(selectPermissionsLoading);

  const isPermissionLoading = token && isLoading && location.pathname.includes('main');

  return isPermissionLoading ? (
    <Box
      sx={{
        width: '100%',
        height: '100svh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: palette.mode === 'dark' ? 'secondary.dark' : 'info.main',
      }}
    >
      <Loader />
    </Box>
  ) : (
    <>{children}</>
  );
};
