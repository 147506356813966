import { Stack, CircularProgress, Typography, MenuItem, Box } from '@mui/material';
import { AvailabilityGroup } from 'entities/Group/index';
import { AvailabilityTableSelect, ItemsBox } from 'shared/ui';
import { FC, ReactNode } from 'react';
import { AvailableGroup, SubType } from 'shared/models';
import { useTranslation } from 'react-i18next';

type Props = {
  groups: AvailableGroup[];
  selectedGroupID: string;
  selectedProductIDs: string[];
  isLoading: boolean;
  toggleGroup: (groupId: string) => void;
  selectGroup: (groupId: string) => void;
  subtypeFilterValue?: string;
  subtypeItems: SubType[];
  onChangeSubtype: (subtypeId: string) => void;
};

export const GroupsTable: FC<Props> = ({
  groups,
  selectedGroupID,
  selectedProductIDs,
  isLoading,
  toggleGroup,
  selectGroup,
  subtypeFilterValue,
  subtypeItems,
  onChangeSubtype,
}) => {
  const { t } = useTranslation();

  return (
    <ItemsBox sx={{ flexGrow: 1, minWidth: 320, maxWidth: 460, maxHeight: 'calc(100svh - 236px - 60px)' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        sx={{
          position: 'sticky',
          top: 0,
          p: 2.5,
          pr: 1,
          height: 48,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          bgcolor: '#fff',
          zIndex: 2,
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>{t('Select Group')}</Typography>

        <AvailabilityTableSelect
          value={subtypeFilterValue}
          renderValue={(selected) =>
            (selected as ReactNode) || <Box sx={{ color: '#242731', fontWeight: 600 }}>{t('subtype')}</Box>
          }
          displayEmpty
        >
          {subtypeItems.map(({ id, name }) => (
            <MenuItem key={id} value={name} onClick={() => onChangeSubtype(id)}>
              {name}
            </MenuItem>
          ))}
        </AvailabilityTableSelect>
      </Stack>

      {groups.length ? (
        groups.map((g) => {
          const products = g.availableComponents;
          const selectedProducts = products.filter((p) => selectedProductIDs.includes(p.id));
          const productsAmountInfo = `${selectedProducts.length}/${products.length}`;

          return (
            <AvailabilityGroup
              key={g.id}
              group={g}
              isChecked={selectedProducts.length === products.length}
              isSelectedForView={g.id === selectedGroupID}
              productsAmountInfo={productsAmountInfo}
              onSelectForView={() => selectGroup(g.id)}
              onToggle={() => toggleGroup(g.id)}
              isLoading={isLoading}
            />
          );
        })
      ) : (
        <Stack alignItems={'center'} sx={{ height: '34px' }}>
          {isLoading ? (
            <CircularProgress size={15} color="primary" sx={{ m: '9px' }} />
          ) : (
            <Typography sx={{ p: '5px', textAlign: 'center' }}>No Groups</Typography>
          )}
        </Stack>
      )}
    </ItemsBox>
  );
};
