import {
  DistributionCurvePlace,
  EdcProfile,
  EdcProfileDTO,
  Group,
  TableProductInfo,
  UpdatedCurveDTO,
} from 'shared/models';
import { CURVE_PLACES_AMOUNT, ORIENTATION, PRODUCT_KEY } from 'shared/constants';

const UPRIGHT_ORIENTATION_VALUE = 0;
const INVERTED_ORIENTATION_VALUE = 1;

const getRelevantDCProperties = ({
  createdById,
  createdDate,
  modifiedById,
  modifiedDate,
  orient,
  places,
  ...properties
}: EdcProfile) => ({
  orient,
  places,
  properties,
});

export const getDistributionCurves = (fetchedProducts: EdcProfile[], groups: Group[], catalogId: string) => {
  const catalogEdcProfiles = fetchedProducts.filter((product) => product.catalogId === catalogId);

  return catalogEdcProfiles.map((product) => {
    const { orient, places, properties } = getRelevantDCProperties(product);

    const relatedGroup = groups?.find((group) => group.id === product.groupId);

    const placesProperties = [...new Array(CURVE_PLACES_AMOUNT)]
      .map((_, i) => ({
        [`place${i}`]: places[i] /* ?? 1 */,
      }))
      .reduce((acc, c) => ({ ...acc, ...c }), {});

    return {
      orient: orient === UPRIGHT_ORIENTATION_VALUE ? ORIENTATION.UPRIGHT : ORIENTATION.INVERTED,
      ...properties,
      ...placesProperties,
      [PRODUCT_KEY.SUBTYPE]: relatedGroup?.subtype?.name ?? '',
    };
  });
};

export const checkIsCurveCellDisabled = (field: string) => {
  const cells: string[] = [PRODUCT_KEY.SKU, PRODUCT_KEY.DESC, PRODUCT_KEY.NOZZLE, PRODUCT_KEY.SWIVEL];
  return cells.includes(field);
};

const getPlacesValues = (product: TableProductInfo) => {
  return [...new Array(CURVE_PLACES_AMOUNT)]
    .map((_, i) => {
      const key = `place${i}` as keyof DistributionCurvePlace;
      return product[key];
    })
    .filter((value): value is number => value !== undefined && value !== null);
};

const getDistributionCurveDTO = (product: TableProductInfo): EdcProfileDTO => {
  const places = getPlacesValues(product);

  return {
    sku: product.sku ?? null,
    groupId: product.groupId ?? '',
    description: product.description ?? null,
    recNum: product.recNum ?? 0,
    nozzle: product.nozzle ?? null,
    swivel: product.swivel ?? null,
    orient: product.orient === ORIENTATION.UPRIGHT ? UPRIGHT_ORIENTATION_VALUE : INVERTED_ORIENTATION_VALUE,
    riser: product.riser ?? null,
    pressure: product.pressure ?? null,
    basedis: product.basedis ?? null,
    flowRate: product.flowRate ?? null,
    range: product.range ?? null,
    places,
  };
};

const getNewCurvesDTOs = (newItemIDs: string[], items: TableProductInfo[]) => {
  const newProducts = items.filter((p) => newItemIDs.includes(p.id));

  const DTOs: EdcProfileDTO[] = newProducts.map(getDistributionCurveDTO);

  return DTOs;
};

const getChangedCurvesDTOs = (changes: TableProductInfo[], items: TableProductInfo[]) => {
  const curveIDs = changes.map((c) => c.id);
  const changedProducts = items.filter((c) => curveIDs.includes(c.id));

  const DTOs: UpdatedCurveDTO[] = changedProducts.map((product) => {
    const params = getDistributionCurveDTO(product);

    return { profileId: product.id, ...params };
  });

  return DTOs;
};

export const getDistributionCurveDTOs = (params: {
  items: TableProductInfo[];
  changedItems: { [id: string]: TableProductInfo };
  newItemIDs: { [id: string]: boolean };
  deletedItemIDs: { [id: string]: boolean };
}) => {
  const { items, changedItems, newItemIDs, deletedItemIDs } = params;

  const deletedCurves = Object.keys(deletedItemIDs);
  const newCurves = getNewCurvesDTOs(Object.keys(newItemIDs), items);

  const changes = Object.values(changedItems).filter(({ id }) => !newItemIDs[id] && !deletedItemIDs[id]);
  const changedCurves = getChangedCurvesDTOs(changes, items);

  return { deletedCurves, newCurves, changedCurves };
};
