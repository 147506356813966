import { Stack, FormControlLabel, Typography, Checkbox } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import { Icon } from 'shared/ui';

type Props = {
  params: GridRenderCellParams;
  openedFilterValues: string[];
  onChangeOpenedFilterValues: (val: string) => void;
  isEditing: boolean;
};

export const UniqFilterItem: FC<Props> = ({
  params: { value: filter },
  openedFilterValues,
  onChangeOpenedFilterValues,
  isEditing,
}) => {
  const filterLabel = typeof filter === 'object' ? filter.name : filter;
  const filterValue = typeof filter === 'object' ? filter.value : filter;

  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <FormControlLabel
        checked={openedFilterValues?.includes(filterValue)}
        onChange={() => onChangeOpenedFilterValues(filterValue)}
        label={
          <Typography noWrap sx={{ color: 'darksome.main' }}>
            {filterLabel ?? '(Blanks)'}
          </Typography>
        }
        disabled={isEditing}
        control={
          <Checkbox icon={<Icon path="checkbox-icon" />} checkedIcon={<Icon path="checkbox-icon-checked" />} />
        }
        sx={{ m: 0, pl: '10px', bgcolor: 'info.main', width: '100%' }}
      />
    </Stack>
  );
};
